const statsTypes = {
  RESET_PUBLISHER_STATE: 'RESET_PUBLISHER_STATE',
  CREATE_PUBLISHER: {
    REQUEST: 'CREATE_PUBLISHER.REQUEST',
    SUCCESS: 'CREATE_PUBLISHER.SUCCESS',
    FAILURE: 'CREATE_PUBLISHER.FAILURE',
  },
  UPDATE_PUBLISHER: {
    REQUEST: 'UPDATE_PUBLISHER.REQUEST',
    SUCCESS: 'UPDATE_PUBLISHER.SUCCESS',
    FAILURE: 'UPDATE_PUBLISHER.FAILURE',
  },
  UPDATE_NETWORK_ID: {
    REQUEST: 'UPDATE_NETWORK_ID.REQUEST',
    SUCCESS: 'UPDATE_NETWORK_ID.SUCCESS',
    FAILURE: 'UPDATE_NETWORK_ID.FAILURE',
  },
  SIGN_AGREEMENTS: {
    REQUEST: 'SIGN_AGREEMENTS.REQUEST',
    SUCCESS: 'SIGN_AGREEMENTS.SUCCESS',
    FAILURE: 'SIGN_AGREEMENTS.FAILURE',
  },
  SYNC_PUBLISHERS: {
    REQUEST: 'SYNC_PUBLISHERS.REQUEST',
    SUCCESS: 'SYNC_PUBLISHERS.SUCCESS',
    FAILURE: 'SYNC_PUBLISHERS.FAILURE',
  },
};

export default statsTypes;
