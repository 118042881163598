import React from 'react';
import {
  ViewButton,
  EditButton,
  TxtFileButton,
} from '../Common/DataTable/tableActionButtons';
import { getLabel } from '../Common/DataTable/tableHelpers';

const sitesNames = (row, sites) => {
  const siteData = sites.filter((site) => site.publisherId === row.id);
  const siteName = siteData ? siteData.map((site) => site.name) : null;
  return siteName
    ? siteName.reduce(
        (accumulator, currentValue) => `${accumulator} ${currentValue},`,
        '',
      )
    : '';
};

const createTableColumns = (
  sites,
  networks,
  openViewModal,
  openEditModal,
  openAdsTxtModal,
) => {
  return [
    {
      title: 'Name',
      name: 'name',
    },
    {
      title: 'Sites',
      name: 'siteIds',
      getCellValue: (row) => sitesNames(row, sites),
      wordWrapEnabled: true,
    },
    {
      title: 'Network',
      name: 'networkId',
      headerStyle: { width: '180px' },
      getCellValue: (row) => getLabel(row.networkId, networks),
    },
    {
      title: 'Model',
      name: 'model',
      width: 130,
      formatterComponent: ({ value }) => {
        if (!value) return '';
        return value === 'adv' ? (
          <span className="badge badge-primary font-size-12">Advertising</span>
        ) : (
          <span className="badge badge-info font-size-12">
            {value.toUpperCase()}
          </span>
        );
      },
    },
    {
      title: 'Active',
      name: 'active',
      width: 100,
      getCellValue: (row) => (row.active ? 'yes' : 'no'),
      formatterComponent: ({ value }) =>
        value === 'yes' ? (
          <span className="badge badge-success font-size-12">YES</span>
        ) : (
          <span className="badge badge-danger font-size-12">NO</span>
        ),
    },
    {
      title: 'Action',
      name: 'action',
      width: 140,
      align: 'center',
      formatterComponent: ({ row }) => (
        <ul className="list-inline font-size-20 contact-links mb-0">
          <ViewButton row={row} onPress={openViewModal} />
          <EditButton row={row} onPress={openEditModal} />
          {row.model === 'adv' && !row.networkId && (
            <TxtFileButton
              row={row}
              onPress={openAdsTxtModal}
              label="Show Ads.txt"
            />
          )}
        </ul>
      ),
      filter: 'disabled',
      sorting: 'disabled',
    },
  ];
};

export default createTableColumns;
