export const PUBLISHER_AGREEMENTS = [
  {
    key: 'guaranteeSigned',
    version: '1.0',
    confirmLabel: {
      en: 'I confirm that I have read and agree to the "GUARANTEE AGREEMENT FOR THE ACTIVATION OF SPEAKUP-ARTICLE SUITE SERVICES"',
      it: 'Confermo di aver letto e di accettare le condiizioni di "ACCORDO DI GARANZIA PER L\'ATTIVAZIONE DEI SERVIZI SUITE SPEAKUP-ARTICLE"',
    },
    path: {
      en: './agreements/publisher-guarantee-agreement-EN.html',
      it: './agreements/publisher-guarantee-agreement-IT.html',
    },
  },
  {
    key: 'dataProcessingSigned',
    version: '1.0',
    confirmLabel: {
      en: 'I confirm that I have read and agree to the "DATA PROCESSING AGREEMENT"',
      it: 'Confermo di aver letto e di accettare le condizioni del "ACCORDO SUL TRATTAMENTO DEI DATI PERSONALI"',
    },
    path: {
      en: './agreements/data-processing-agreement-EN.html',
      it: './agreements/data-processing-agreement-IT.html',
    },
  },
];
