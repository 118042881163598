import React from 'react';

import {
  Switch,
  BrowserRouter as Router,
  Route,
  Redirect,
} from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { actualCountry } from './selectors/country';

// Import Routes all
import { userRoutes, authRoutes } from './routes/allRoutes';
import { getAllowedRoutes } from './helpers/authHelper';

// Import all middleware
import AuthMiddleware from './routes/middleware/Authmiddleware';

// layouts Format
import VerticalLayout from './components/VerticalLayout/';
import NonAuthLayout from './components/NonAuthLayout';

// Import scss
import './assets/scss/theme.scss';
import 'toastr/build/toastr.min.css';

const App = (props) => {
  const admin = useSelector((state) => state.Auth.admin);
  const country = useSelector(actualCountry);

  const privateRoutes = getAllowedRoutes(userRoutes, admin, country);

  const NonAuthMiddleware = ({
    component: Component,
    layout: VerticalLayout,
  }) => {
    const loggedIn = useSelector((state) => state.Auth.loggedIn);
    const user = useSelector((state) => state.Auth.admin);
    return (
      <Route
        render={(props) => {
          const { location, history } = props;
          //when user enter login address on browser address bar then redirect to home
          if (
            loggedIn &&
            user &&
            location.pathname === '/login' &&
            history.action === 'POP'
          ) {
            return <Redirect to="/dashboard" />;
          }

          return (
            <VerticalLayout>
              <Component {...props} />
            </VerticalLayout>
          );
        }}
      />
    );
  };

  return (
    <React.Fragment>
      <Router>
        <Switch>
          {authRoutes.map((route, idx) => (
            <NonAuthMiddleware
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
            />
          ))}

          {privateRoutes.map((route, idx) => (
            <AuthMiddleware
              path={route.path}
              layout={route.layout || VerticalLayout}
              component={route.component}
              key={idx}
            />
          ))}
        </Switch>
      </Router>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    layout: state.Layout,
  };
};

export default connect(mapStateToProps, null)(App);
