import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  CardBody,
  Card,
  FormGroup,
  Label,
  Alert,
  Container,
  Spinner,
} from 'reactstrap';
import { Form, Input } from '../../lib/availity/form';
import FormErrorMessage from '../../components/Common/FormErrorMessage';
import ReCAPTCHA from 'react-google-recaptcha';
import { withRouter, Link, useLocation } from 'react-router-dom';
import * as authActions from '../../store/auth/actions';
import { useDispatch, useSelector } from 'react-redux';
import DisclaimerFooter from '../../components/Common/DisclaimerFooter';

import '../../lib/availity/yup';
import * as yup from 'yup';

import logotype from '../../assets/images/logo-dark.png';
import logo from '../../assets/images/logo-sm-light-square.png';

const formValidation = yup.object().shape({
  firstName: yup.string().required().label('First Name'),
  lastName: yup.string().required().label('Last Name'),
  email: yup.string().email().required(),
  password: yup
    .string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters')
    .matches(/[0-9]/, 'Password must contain at least one digit')
    .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
    .matches(
      /[!@#$%^&*(),.?":{}|<>]/,
      'Password must contain at least one symbol',
    ),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Passwords must match')
    .required()
    .label('Confirm Password'),
});

const Register = ({ history }) => {
  const loading = useSelector((state) => state.Auth.loading);
  const error = useSelector((state) => state.Auth.error);
  const success = useSelector((state) => state.Auth.success);

  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const [signupInfo, setSignupInfo] = useState(null);

  const { search } = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(search);
    const queryMap = {};
    for (const [key, value] of queryParams.entries()) {
      queryMap[key] = value;
    }
    setSignupInfo(queryMap);
  }, [search]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(authActions.resetMessages());
  }, []);

  const handleLoginWithGoogle = () => {
    dispatch(authActions.loginWithGoogle(history, signupInfo));
  };

  const handleSubmit = ({ email, password, firstName, lastName }) => {
    dispatch(
      authActions.registerWithEmail(
        email,
        password,
        firstName,
        lastName,
        signupInfo,
        history,
      ),
    );
  };

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2"></i>
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-soft-primary">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome</h5>
                        <p>Enter the magical world of AudioBoost</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={logotype} alt="" className="img-fluid p-2" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <h3 className="text-center">Create Your Account</h3>
                  <div className="mt-5">
                    <button
                      className="btn btn-outline-dark btn-block waves-effect waves-light"
                      type="button"
                      onClick={handleLoginWithGoogle}
                      disabled={loading}>
                      <i className="bx bxl-google font-size-18 align-middle mr-2"></i>
                      Sign up with Google
                    </button>
                  </div>
                  <div className="my-4">
                    <div className="d-flex align-items-center">
                      <hr style={{ flex: '1', borderTop: '1px solid #ccc' }} />
                      <div className="px-4 text-base text-gray">
                        or fill in your details
                      </div>
                      <hr style={{ flex: '1', borderTop: '1px solid #ccc' }} />
                    </div>
                  </div>
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      initialValues={{}}
                      validationSchema={formValidation}
                      onSubmit={handleSubmit}>
                      {error && <Alert color="danger">{error}</Alert>}
                      {success && <Alert color="success">{success}</Alert>}
                      <FormGroup>
                        <Label htmlFor="firstName">First Name</Label>
                        <Input
                          id="firstName"
                          name="firstName"
                          placeholder="Your First Name"
                          disabled={loading}
                        />
                        <FormErrorMessage name="firstName" />
                      </FormGroup>
                      <FormGroup>
                        <Label htmlFor="lastName">Last Name</Label>
                        <Input
                          id="lastName"
                          name="lastName"
                          placeholder="Your Last Name"
                          disabled={loading}
                        />
                        <FormErrorMessage name="lastName" />
                      </FormGroup>
                      <FormGroup>
                        <Label htmlFor="email">Email</Label>
                        <Input
                          id="email"
                          name="email"
                          placeholder="Your Email"
                          disabled={loading}
                        />
                        <FormErrorMessage name="email" />
                      </FormGroup>
                      <FormGroup>
                        <Label htmlFor="password">Password</Label>
                        <Input
                          id="password"
                          name="password"
                          type="password"
                          placeholder="Your Password"
                          disabled={loading}
                        />
                        <FormErrorMessage name="password" />
                      </FormGroup>
                      <FormGroup>
                        <Label htmlFor="confirmPassword">
                          Confirm Password
                        </Label>
                        <Input
                          id="confirmPassword"
                          name="confirmPassword"
                          type="password"
                          disabled={loading}
                        />
                        <FormErrorMessage name="confirmPassword" />
                      </FormGroup>
                      <div className="mt-4">
                        <ReCAPTCHA
                          sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                          onChange={setRecaptchaToken}
                        />
                      </div>
                      <div className="mt-4 text-center">
                        <p className="mb-0">
                          By clicking register you agree to the{' '}
                          <a
                            href="https://audioboost.com/term-and-condition/"
                            target="_blank"
                            rel="noopener noreferrer">
                            Term and General Conditions
                          </a>
                          . Your data will be handled in accordance with our{' '}
                          <a
                            href="https://audioboost.com/privacypolicy/"
                            target="_blank"
                            rel="noopener noreferrer">
                            Privacy Policy
                          </a>
                          .
                        </p>
                      </div>
                      <div className="mt-4">
                        <button
                          className="btn btn-primary btn-block waves-effect waves-light"
                          type="submit"
                          disabled={loading || !recaptchaToken}>
                          {loading ? <Spinner size="sm" /> : 'Register'}
                        </button>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Already have an account ?{' '}
                  <Link to="/login" className="font-weight-medium text-primary">
                    {' '}
                    Login
                  </Link>{' '}
                </p>
                <DisclaimerFooter />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Register);
