import { REVENUE_LEVEL } from '../config/revenue';
import { ADS_TXT_STATUS } from '../config/site';
import { createSelector } from 'reselect';

export const selectSitesByPublisher = (publisherId, onlyActive = false) =>
  createSelector(
    (state) => state.Site.sites,
    (sites) => {
      if (!publisherId) return [];
      const selectedSites = sites.filter(
        (site) => site.publisherId === publisherId,
      );
      return onlyActive
        ? selectedSites.filter((site) => site.active)
        : selectedSites;
    },
  );

export const selectSitesByNetwork = (networkId, onlyActive = false) =>
  createSelector(
    (state) => state.Site.sites,
    (sites) => {
      if (!networkId) return [];
      const selectedSites = sites.filter(
        (site) => site.networkId === networkId,
      );
      return onlyActive
        ? selectedSites.filter((site) => site.active)
        : selectedSites;
    },
  );

export const selectSeedingSitesByPublisher = (
  publisherId,
  onlyActive = false,
) =>
  createSelector(selectSitesByPublisher(publisherId, onlyActive), (sites) =>
    sites.filter((site) => !!site.gamAdUnitSeedingId),
  );

export const selectSeedingSites = createSelector(
  (state) => state.Site.sites,
  (sites) => sites.filter((site) => !!site.gamAdUnitSeedingId),
);

export const getRevenue = (level) =>
  createSelector(
    (state) => state.SiteRevenue.revenues,
    (state) => state.MonthlySiteRevenue.revenues,
    (daily, monthly) => {
      if (level === REVENUE_LEVEL.DAILY) return daily;
      if (level === REVENUE_LEVEL.MONTHLY) return monthly;
      return daily;
    },
  );

export const totalMetric = (metric, level) =>
  createSelector(getRevenue(level), (revenues) =>
    revenues.map((revenue) => revenue[metric] || 0).reduce((a, b) => a + b, 0),
  );

export const selectIncompleteAdsTxtPublisherSites = (publisherId) =>
  createSelector(selectSitesByPublisher(publisherId, true), (sites) =>
    sites.filter((site) => site.adsTxtStatus !== ADS_TXT_STATUS.COMPLETE),
  );

export const selectIncompleteAdsTxtNetworkSites = (networkId) =>
  createSelector(selectSitesByNetwork(networkId, true), (sites) =>
    sites.filter((site) => site.adsTxtStatus !== ADS_TXT_STATUS.COMPLETE),
  );

export const selectIncompleteAdsTxtSites = (id, type) => {
  return type === 'publisher'
    ? selectIncompleteAdsTxtPublisherSites(id)
    : selectIncompleteAdsTxtNetworkSites(id);
};
