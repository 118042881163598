import types from './actionTypes';

const initialState = {
  loading: false,
  publishers: [],
  error: '',
};

const publisherReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.RESET_PUBLISHER_STATE:
      return initialState;
    case types.CREATE_PUBLISHER.REQUEST:
    case types.UPDATE_PUBLISHER.REQUEST:
    case types.UPDATE_NETWORK_ID.REQUEST:
    case types.SIGN_AGREEMENTS.REQUEST:
    case types.SYNC_PUBLISHERS.REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case types.CREATE_PUBLISHER.SUCCESS:
    case types.UPDATE_PUBLISHER.SUCCESS:
    case types.UPDATE_NETWORK_ID.SUCCESS:
    case types.SIGN_AGREEMENTS.SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
      };
    case types.SYNC_PUBLISHERS.SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        publishers: [...action.publishers],
      };
    case types.CREATE_PUBLISHER.FAILURE:
    case types.UPDATE_PUBLISHER.FAILURE:
    case types.UPDATE_NETWORK_ID.FAILURE:
    case types.SIGN_AGREEMENTS.FAILURE:
    case types.SYNC_PUBLISHERS.FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error.message,
      };
    default:
      return state;
  }
};

export default publisherReducer;
