import { createSelector } from 'reselect';
import {
  isAdmin,
  isManager,
  userPublisherId,
  userNetworkId,
  userCustomerId,
} from './auth';
import { isEmptyObj } from '../helpers/sharedFunction';
import roles from '../config/roles';
import { PUBLISHER_AGREEMENTS } from '../config/publisher';

export const actualPublisher = createSelector(
  (state) => state.Publisher.publishers,
  userPublisherId,
  (publishers, publisherId) =>
    publisherId
      ? publishers.find((publisher) => publisher.id === publisherId)
      : null,
);

export const selectPublisher = (id) =>
  createSelector(
    (state) => state.Publisher.publishers,
    (publishers) =>
      id ? publishers.find((publisher) => publisher.id === id) : null,
  );

export const selectPublishersForNetworkSelect = (network) =>
  createSelector(
    (state) => state.Publisher.publishers,
    (publishers) => {
      let data = [];

      if (isEmptyObj(network)) {
        publishers.forEach((publisher) => {
          !publisher.networkId &&
            data.push({
              value: publisher.id,
              label: publisher.name,
            });
        });
      } else {
        publishers.forEach((publisher) => {
          (!publisher.networkId ||
            network.publisherIds.includes(publisher.id)) &&
            data.push({
              value: publisher.id,
              label: publisher.name,
            });
        });
      }

      return data;
    },
  );

export const selectPublishersWithoutCustomer = (customerId) =>
  createSelector(
    (state) => state.Publisher.publishers,
    (publishers) => {
      const freePublishers = publishers.filter(({ customerId }) => !customerId);
      return customerId
        ? [
            ...publishers.filter(
              (publisher) => publisher.customerId === customerId,
            ),
            ...freePublishers,
          ]
        : freePublishers;
    },
  );

export const selectPublishersWithCustomer = createSelector(
  (state) => state.Publisher.publishers,
  (publishers) => publishers.filter(({ customerId }) => !!customerId),
);

export const selectPublishersByNetwork = (networkId) =>
  createSelector(
    (state) => state.Publisher.publishers,
    (publishers) =>
      networkId
        ? publishers.filter((publisher) => publisher.networkId === networkId)
        : [],
  );

export const selectPublishersByRole = (role, id = null) =>
  createSelector(
    (state) => state.Publisher.publishers,
    (publishers) => {
      if ([roles.ADMIN, roles.SUPER_ADMIN, roles.MANAGER].includes(role))
        return publishers;
      if (role === roles.NETWORKER)
        return publishers.filter((publisher) => publisher.networkId === id);
      if (role === roles.PUBLISHER)
        return publishers.filter((publisher) => publisher.id === id);
      return [];
    },
  );

export const selectPublishersByActualRole = createSelector(
  isAdmin,
  isManager,
  userNetworkId,
  userCustomerId,
  userPublisherId,
  (state) => state.Publisher.publishers,
  (isAdmin, isManager, networkId, customerId, publisherId, publishers) => {
    if (isAdmin || isManager) return publishers;
    if (networkId)
      return publishers.filter(
        (publisher) => publisher.networkId === networkId,
      );
    if (customerId)
      return publishers.filter(
        (publisher) => publisher.customerId === customerId,
      );
    if (publisherId)
      return publishers.filter((publisher) => publisher.id === publisherId);
    return [];
  },
);

export const publisherAgreementSigned = createSelector(
  actualPublisher,
  (publisher) => {
    if (!publisher) return true;
    const agreements = publisher.agreements || {};

    return PUBLISHER_AGREEMENTS.every(
      (a) =>
        agreements[`${a.key}Date`] &&
        agreements[`${a.key}Version`] === a.version,
    );
  },
);
