import React, { useState, useCallback } from 'react';
import { Row, Col, Card, CardBody, Alert } from 'reactstrap';
import { actualCountry } from '../../selectors/country';
import { selectIncompleteAdsTxtSites } from '../../selectors/site';
import { useSelector } from 'react-redux';
import CodeBlock from '../Common/CodeBlock';
import Modal from '../Common/Modal/Modal';
import colors from '../CommonStyle/colors';

const ShowAdsTxt = ({ element, type }) => {
  const { id: sellerId, name, truvidSellerId } = element;
  const { name: countryName, adsTxtRows } = useSelector(actualCountry);

  const adsTxt = Array.isArray(adsTxtRows)
    ? adsTxtRows
        .join('\n')
        .replace('{{sellerId}}', sellerId)
        .replace('{{truvidSellerId}}', truvidSellerId || 'XXXXXX')
    : '';

  const isValid = !!truvidSellerId;

  const handleDownload = useCallback(() => {
    const blob = new Blob([adsTxt], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `Ads.txt for ${name} - Audioboost.txt`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  }, [adsTxt, name]);

  return (
    <Card>
      <CardBody>
        <Row className="text-center m-3">
          <Col>
            <h2 style={{ color: colors.indigo }}>
              Audioboost Ads.txt file for {name}
            </h2>
          </Col>
        </Row>
        <Row>
          <Col>
            {!isValid && adsTxt && (
              <Alert color="warning">{`The Ads.txt is not valid! You have to insert Truvid Sellers.json ID into ${
                type === 'network'
                  ? 'Network'
                  : type === 'site'
                  ? 'Site'
                  : 'Publisher'
              } configuration.`}</Alert>
            )}
            {!adsTxt && (
              <Alert color="danger">
                {`The Ads.txt is not available for this publisher in ${countryName}. You have to update country configuration with the Ads.txt schema.`}
              </Alert>
            )}
            {adsTxt && <CodeBlock code={adsTxt} language="plaintext" />}
          </Col>
        </Row>
        <Row className="text-center">
          <Col>
            <button
              type="button"
              className="waves-effect waves-light btn btn-success"
              disabled={!(isValid && adsTxt)}
              onClick={handleDownload}>
              <i className="bx bx-download align-middle mr-1"></i>
              Download Ads.txt
            </button>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export const AdsTxtAlert = ({ element, type }) => {
  const sites = useSelector(selectIncompleteAdsTxtSites(element?.id, type));
  const [adsTxtModal, setAdsTxtModal] = useState(false);

  const isValid = sites.length === 0;

  return (
    <Alert color={isValid ? 'success' : 'warning'}>
      <div className="d-flex align-items-center">
        <div className="mr-4 avatar-sm align-self-center rounded-circle overflow-hidden">
          <span
            className={`avatar-title bg-${isValid ? 'success' : 'warning'}`}>
            <i
              className={`bx bx-${
                isValid ? 'check-circle' : 'error'
              } font-size-24`}></i>
          </span>
        </div>
        <div className="mr-4">
          <h5 className="alert-heading">
            Ads.txt configuration {isValid ? 'complete' : 'incomplete'}
          </h5>
          <p className="mb-0">
            {isValid
              ? 'All your sites have completed the Ads.txt configuration.'
              : `Some sites have not completed the Ads.txt configuration. Check this sites to avoid revenue loss: ${sites
                  .map(({ name }) => name)
                  .join(', ')}.`}
          </p>
        </div>
        <div>
          <button
            type="button"
            className="waves-effect waves-light btn btn-sm btn-dark"
            onClick={() => setAdsTxtModal(true)}>
            <i className="fas fa-ad align-middle mr-1"></i>
            {'Show Ads.txt'}
          </button>
        </div>
      </div>
      <Modal
        size="lg"
        title="Get Ads.txt file for Publisher"
        isOpen={adsTxtModal}
        toggle={() => setAdsTxtModal(!adsTxtModal)}
        scrollable={false}>
        <ShowAdsTxt element={element} type={type} />
      </Modal>
    </Alert>
  );
};

export default ShowAdsTxt;
