import React from 'react';
import {
  ViewButton,
  EditButton,
  TxtFileButton,
} from '../Common/DataTable/tableActionButtons';
import { getLabels } from '../Common/DataTable/tableHelpers';

const createTableColumns = (
  publishers,
  openViewModal,
  openEditModal,
  openAdsTxtModal,
) => [
  {
    title: 'Name',
    name: 'name',
  },
  {
    title: 'Publishers',
    name: 'publisherIds',
    getCellValue: (row) => getLabels(row.publisherIds, publishers),
  },
  {
    title: 'Active',
    name: 'active',
    width: 100,
    getCellValue: (row) => (row.active ? 'yes' : 'no'),
    formatterComponent: ({ value }) =>
      value === 'yes' ? (
        <span className="badge badge-success font-size-12">YES</span>
      ) : (
        <span className="badge badge-danger font-size-12">NO</span>
      ),
  },
  {
    title: 'Action',
    name: 'action',
    width: 140,
    align: 'center',
    formatterComponent: ({ row }) => (
      <ul className="list-inline font-size-20 contact-links mb-0">
        <ViewButton row={row} onPress={openViewModal} />
        <EditButton row={row} onPress={openEditModal} />
        <TxtFileButton
          row={row}
          onPress={openAdsTxtModal}
          label="Show Ads.txt"
        />
      </ul>
    ),
    filter: 'disabled',
    sorting: 'disabled',
  },
];

export default createTableColumns;
