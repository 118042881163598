import React, { useEffect } from 'react';
import { Media } from 'reactstrap';
import { selectCountry } from '../../selectors/country';
import * as countriesActions from '../../store/countries/actions';
import { useDispatch, useSelector } from 'react-redux';

const CountryBox = ({ countryId }) => {
  const country = useSelector(selectCountry(countryId));

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(countriesActions.syncCountries());
  }, []);

  return (
    <div className="d-inline-block mr-4 mini-stats-wid">
      <Media className="header-item">
        <div className="align-self-center mr-2">
          <span className="avatar-xs rounded-circle">
            <i className="bx bx-world font-size-22 text-info"></i>
          </span>
        </div>
        <div className="align-self-center mr-3">
          <h5 className="font-size-15 mt-0 mb-1">
            {country ? country.name : ''}
          </h5>
        </div>
        {process.env.REACT_APP_ENVIRONMENT === 'dev' && (
          <div className="align-self-center mr-3">
            <span className="badge badge-danger font-size-14">DEV</span>
          </div>
        )}
      </Media>
    </div>
  );
};

export default CountryBox;
