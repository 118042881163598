import React, { useState, useEffect, useRef } from 'react';
import { actualCountry } from '../../selectors/country';
import { useDispatch, useSelector } from 'react-redux';
import * as publishersActions from '../../store/publishers/actions';
import { useTranslation } from 'react-i18next';
import { PUBLISHER_AGREEMENTS } from '../../config/publisher';

const Agreement = ({ agreement, lang, onSigned, btnLabel }) => {
  const userId = useSelector((state) => state.Auth.admin.id);

  const [content, setContent] = useState('');
  const [signed, setSigned] = useState(false);

  useEffect(() => {
    setSigned(false);
    fetch(`${agreement.path[lang]}`)
      .then((response) => response.text())
      .then((html) => {
        setContent(html);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }, [agreement, lang]);

  const handleOnSigned = () => {
    onSigned({
      [`${agreement.key}Version`]: agreement.version,
      [`${agreement.key}Date`]: new Date().toISOString(),
      [`${agreement.key}By`]: userId,
    });
  };

  const { t } = useTranslation();

  return (
    <>
      <div
        className="mx-1 my-3 px-4 overflow-auto"
        dangerouslySetInnerHTML={{ __html: content }}></div>
      <div className="modal-footer flex-nowrap justify-content-start">
        <div
          className="form-check form-check-end"
          onClick={() => setSigned(!signed)}>
          <input
            className="form-check-input"
            type="checkbox"
            checked={signed}
          />
          <label className="form-check-label">
            {agreement.confirmLabel[lang]}
          </label>
        </div>
        <button
          type="button"
          className="btn btn-primary ml-auto ml-4"
          disabled={!signed}
          onClick={handleOnSigned}>
          {t(btnLabel)}
        </button>
      </div>
    </>
  );
};

const SignAgreements = () => {
  const country = useSelector(actualCountry);

  const lang = country.languageTag.split('-')[0] === 'it' ? 'it' : 'en';

  const [step, setStep] = useState(0);
  const agreements = useRef({});
  const lastStep = step === PUBLISHER_AGREEMENTS.length - 1;

  const dispatch = useDispatch();

  const handleOnSigned = (agreement) => {
    agreements.current = { ...agreements.current, ...agreement };
    if (lastStep) {
      dispatch(publishersActions.signAgreements(agreements.current));
    } else setStep(step + 1);
  };

  return (
    <Agreement
      agreement={PUBLISHER_AGREEMENTS[step]}
      lang={lang}
      onSigned={handleOnSigned}
      btnLabel={lastStep ? 'Close' : 'Next'}
    />
  );
};

export default SignAgreements;
