import React, { useState, useEffect } from 'react';
import { FormGroup } from 'reactstrap';
import { Form } from '../../lib/availity/form';
import { Select } from '../../lib/availity/select';
import '../../lib/availity/yup';
import * as yup from 'yup';

import * as dashboardActions from '../../store/dashboard/actions';
import * as countriesActions from '../../store/countries/actions';
import { availableCountries } from '../../selectors/country';
import { useDispatch, useSelector } from 'react-redux';

import Alert from '../Common/Modal/Alert';
import { useTranslation } from 'react-i18next';

const SelectCountryAlert = (props) => {
  const [countryId, setCountryId] = useState('');
  const [disabledAlertBtn, setDisabledAlertBtn] = useState(true);

  const countries = useSelector(availableCountries);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(countriesActions.syncCountries());
  }, []);

  const onConfirm = () => {
    setDisabledAlertBtn(true);
    dispatch(dashboardActions.setCountry(countryId));
    props.onCancel();
  };

  const handleCampaign = (value) => {
    setCountryId(value);
    setDisabledAlertBtn(false);
  };

  const { t } = useTranslation();

  return (
    <Alert
      isOpen={props.isOpen}
      title={t('Choose the country')}
      mainIcon={
        <div className="avatar-sm mx-auto my-2">
          <span className="avatar-title rounded-circle bg-info font-size-24">
            <i className="bx bx-world"></i>
          </span>
        </div>
      }
      confirmBtnLabel={t('Confirm')}
      onConfirm={onConfirm}
      disabledBtn={disabledAlertBtn}>
      <Form
        initialValues={{ countryId }}
        validationSchema={yup.object().shape({
          countryId: yup.string().required(),
        })}>
        <FormGroup>
          <Select
            name="countryId"
            id="countryId"
            isMulti={false}
            onChange={handleCampaign}
            value={countryId}
            options={countries.map(({ id, name }) => ({
              label: name,
              value: id,
            }))}
          />
        </FormGroup>
      </Form>
    </Alert>
  );
};

export default SelectCountryAlert;
