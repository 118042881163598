import '../../lib/availity/yup';
import * as yup from 'yup';

const formValidation = yup.object().shape({
  name: yup.string().required(),
  active: yup.boolean().required(),
  publisherIds: yup.array().min(1).required(),
  headerImageUrl: yup.string().url(),
  domain: yup.string(),
  truvidSellerId: yup.string(),
});

export default formValidation;
