import React from 'react';
import { Redirect } from 'react-router-dom';
import roles from '../config/roles';

// Authentication related pages
import Login from '../pages/Authentication/Login';
import Register from '../pages/Authentication/Register';
import Logout from '../pages/Authentication/Logout';
import EmailVerification from '../pages/Authentication/EmailVerification';
import EmailConfirmation from '../pages/Authentication/EmailConfirmation';
import AccountConfirmation from '../pages/Authentication/AccountConfirmation';
import ForgetPassword from '../pages/Authentication/ForgetPassword';

// All protected pages
import Dashboard from '../pages/Dashboard/index';
import NetworksList from '../pages/Networks/List';
import NetworksInsights from '../pages/Networks/Insights';
import PublishersList from '../pages/Publishers/List';
import PublisherInsights from '../pages/Publishers/Insights';
import SitesList from '../pages/Sites/List';
import SiteInsights from '../pages/Sites/Insights';
import SiteRevenues from '../pages/Sites/Revenues';
import MonthlySiteRevenues from '../pages/Sites/MonthlyRevenues';
import RevenueList from '../pages/Revenues/List';
import SiteNetRevenueList from '../pages/Revenues/SiteNetRevenues';
import PodcastsList from '../pages/Podcasts/List';
import PlaylistsList from '../pages/Playlists/List';
import CustomerList from '../pages/Customers/List';
import ClustersList from '../pages/Clusters/List';
import CampaignsList from '../pages/Campaigns/List';
import Creatives from '../pages/Campaigns/Creatives';
//import CampaignInsights from '../pages/Campaigns/Insights';
import UsersList from '../pages/Users/List';
import CountriesList from '../pages/Countries/List';
import CountryInsights from '../pages/Countries/Insights';
import Onboarding from '../pages/Onboarding';
import Settings from '../pages/Settings';

import NoMenuLayout from '../components/NoMenuLayout/';

const userRoutes = [
  {
    title: 'Dashboard',
    path: '/dashboard',
    icon: 'bx bx-home-alt',
    component: Dashboard,
  },
  {
    group: 'Publishers',
    icon: 'bx bx-buildings',
    children: [
      {
        title: 'Networks',
        path: '/networks/list',
        component: NetworksList,
        permission: [roles.SUPER_ADMIN, roles.ADMIN],
      },
      {
        title: 'Network Insights',
        path: '/networks/insights',
        component: NetworksInsights,
        permission: [
          roles.SUPER_ADMIN,
          roles.ADMIN,
          roles.MANAGER,
          roles.NETWORKER,
        ],
      },
      {
        title: 'Publishers',
        path: '/publishers/list',
        component: PublishersList,
        permission: [roles.SUPER_ADMIN, roles.ADMIN],
      },
      {
        title: 'Publisher Insights',
        path: '/publishers/insights',
        component: PublisherInsights,
        permission: [roles.SUPER_ADMIN, roles.ADMIN, roles.MANAGER],
      },
    ],
  },
  {
    group: 'Sites',
    icon: 'mdi mdi-web',
    children: [
      {
        title: 'Sites',
        path: '/sites/list',
        component: SitesList,
        permission: [roles.SUPER_ADMIN, roles.ADMIN],
      },
      {
        title: 'Insights',
        path: '/sites/insights',
        component: SiteInsights,
        permission: [
          roles.SUPER_ADMIN,
          roles.ADMIN,
          roles.MANAGER,
          roles.NETWORKER,
          roles.PUBLISHER,
        ],
      },
      {
        title: 'Revenues',
        path: '/sites/revenues',
        component: SiteRevenues,
        permission: [roles.SUPER_ADMIN, roles.ADMIN],
      },
      {
        title: 'Monthly Revenues',
        path: '/sites/monthly-revenues',
        component: MonthlySiteRevenues,
        permission: [
          roles.SUPER_ADMIN,
          roles.ADMIN,
          roles.NETWORKER,
          roles.PUBLISHER,
        ],
      },
    ],
  },
  {
    group: 'Revenues',
    icon: 'bx bx-dollar-circle',
    children: [
      {
        title: 'Gross Revenues',
        path: '/revenues/list',
        component: RevenueList,
        permission: [roles.SUPER_ADMIN, roles.ADMIN],
      },
      {
        title: 'Site Revenues',
        path: '/revenues/site/list',
        component: SiteNetRevenueList,
        permission: [
          roles.SUPER_ADMIN,
          roles.ADMIN,
          roles.NETWORKER,
          roles.PUBLISHER,
        ],
      },
    ],
  },
  {
    group: 'Spoken Articles',
    icon: 'bx bx-headphone',
    children: [
      {
        title: 'Spoken Articles',
        path: '/podcasts/list',
        component: PodcastsList,
        permission: [
          roles.SUPER_ADMIN,
          roles.ADMIN,
          roles.PUBLISHER,
          roles.QUALITY_CHECKER,
        ],
      },
      {
        title: 'Semantic Clusters',
        path: '/clusters/list',
        component: ClustersList,
        permission: [roles.SUPER_ADMIN, roles.ADMIN],
      },
    ],
  },
  {
    title: 'Playlist',
    path: '/playlists/list',
    component: PlaylistsList,
    icon: 'bx bx bxs-playlist',
    permission: [roles.SUPER_ADMIN, roles.ADMIN],
  },
  {
    title: 'Customer',
    path: '/customers/list',
    component: CustomerList,
    icon: 'far fa-handshake',
    permission: [roles.SUPER_ADMIN, roles.ADMIN],
  },
  {
    group: 'Campaigns',
    icon: 'dripicons-broadcast',
    children: [
      {
        title: 'Campaigns',
        path: '/campaigns/list',
        component: CampaignsList,
        permission: [
          roles.SUPER_ADMIN,
          roles.ADMIN,
          roles.MANAGER,
          roles.CUSTOMER,
          roles.PUBLISHER,
        ],
      },
      {
        title: 'Creatives',
        path: '/campaigns/creatives/list',
        component: Creatives,
        permission: [roles.SUPER_ADMIN, roles.ADMIN, roles.MANAGER],
      },
      // {
      //   title: 'Insights',
      //   path: '/campaigns/insights',
      //   component: CampaignInsights,
      //   permission: [roles.SUPER_ADMIN, roles.ADMIN],
      // },
    ],
  },
  {
    title: 'Users',
    path: '/users/list',
    component: UsersList,
    icon: 'bx bx bx-user-circle',
    permission: [roles.SUPER_ADMIN, roles.ADMIN],
  },
  {
    group: 'Countries',
    icon: 'bx bx-world',
    children: [
      {
        title: 'Countries',
        path: '/countries/list',
        component: CountriesList,
        permission: [roles.SUPER_ADMIN, roles.ADMIN],
      },
      {
        title: 'Insights',
        path: '/countries/insights',
        component: CountryInsights,
        permission: [roles.SUPER_ADMIN, roles.ADMIN],
      },
    ],
  },
  {
    path: '/onboarding',
    component: Onboarding,
    layout: NoMenuLayout,
    permission: [roles.PUBLISHER],
  },
  {
    path: '/settings',
    component: Settings,
  },

  // this route should be at the end of all other routes
  { path: '/', exact: true, component: () => <Redirect to="/dashboard" /> },
];

const authRoutes = [
  { path: '/logout', component: Logout },
  { path: '/login', component: Login },
  { path: '/register', component: Register },
  { path: '/email-verification', component: EmailVerification },
  { path: '/email-confirmation', component: EmailConfirmation },
  { path: '/account-confirmation', component: AccountConfirmation },
  { path: '/forgot-password', component: ForgetPassword },
];

export { userRoutes, authRoutes };
