import React from 'react';
import { Modal as StrapModal } from 'reactstrap';
import { useTranslation } from 'react-i18next';

const Modal = ({
  isOpen,
  className,
  title,
  toggle,
  children,
  showHeaderClose = true,
  showClose,
  ...props
}) => {
  const { t } = useTranslation();
  return (
    <StrapModal
      {...props}
      isOpen={isOpen}
      className={className}
      backdrop="static">
      <div className="modal-header">
        <h5 className="modal-title mt-0">{t(title)}</h5>
        {showHeaderClose && (
          <button
            type="button"
            onClick={toggle}
            className="close"
            data-dismiss="modal"
            aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        )}
      </div>
      {children}
      {showClose && (
        <div className="modal-footer">
          <button type="button" className="btn btn-secondary" onClick={toggle}>
            {t('Close')}
          </button>
        </div>
      )}
    </StrapModal>
  );
};

export default Modal;
