import types from './actionTypes';

export const resetMessages = () => ({
  type: types.RESET_MESSAGES,
});

export const loginWithGoogle = (history, signupInfo) => ({
  type: types.LOGIN_WITH_GOOGLE.REQUEST,
  history,
  signupInfo,
});

export const loginWithGoogleSuccess = (credential) => ({
  type: types.LOGIN_WITH_GOOGLE.SUCCESS,
  credential,
});

export const loginWithGoogleFailure = (error) => ({
  type: types.LOGIN_WITH_GOOGLE.FAILURE,
  error,
});

export const loginWithEmail = (email, password, history) => ({
  type: types.LOGIN_WITH_EMAIL.REQUEST,
  email,
  password,
  history,
});

export const loginWithEmailSuccess = (credential) => ({
  type: types.LOGIN_WITH_EMAIL.SUCCESS,
  credential,
});

export const loginWithEmailFailure = (error) => ({
  type: types.LOGIN_WITH_EMAIL.FAILURE,
  error,
});

export const registerWithEmail = (
  email,
  password,
  firstName,
  lastName,
  signupInfo,
  history,
) => ({
  type: types.REGISTER_WITH_EMAIL.REQUEST,
  email,
  password,
  firstName,
  lastName,
  signupInfo,
  history,
});

export const registerWithEmailSuccess = () => ({
  type: types.REGISTER_WITH_EMAIL.SUCCESS,
});

export const registerWithEmailFailure = (error) => ({
  type: types.REGISTER_WITH_EMAIL.FAILURE,
  error,
});

export const updateAdmin = (details) => ({
  type: types.UPDATE_ADMIN.REQUEST,
  details,
});

export const updateAdminSuccess = (details) => ({
  type: types.UPDATE_ADMIN.SUCCESS,
});

export const updateAdminFailure = (error) => ({
  type: types.UPDATE_ADMIN.FAILURE,
  error,
});

export const passwordChange = (email, currentPassword, newPassword) => ({
  type: types.PASSWORD_CHANGE.REQUEST,
  email,
  currentPassword,
  newPassword,
});

export const passwordChangeSuccess = (credential) => ({
  type: types.PASSWORD_CHANGE.SUCCESS,
});

export const passwordChangeFailure = (error) => ({
  type: types.PASSWORD_CHANGE.FAILURE,
  error,
});

export const passwordForget = (email) => ({
  type: types.PASSWORD_FORGET.REQUEST,
  email,
});

export const passwordForgetSuccess = (message) => ({
  type: types.PASSWORD_FORGET.SUCCESS,
  message,
});

export const passwordForgetFailure = (error) => ({
  type: types.PASSWORD_FORGET.FAILURE,
  error,
});

export const sendEmailVerification = (email) => ({
  type: types.SEND_EMAIL_VERIFICATION.REQUEST,
  email,
});

export const sendEmailVerificationSuccess = (message) => ({
  type: types.SEND_EMAIL_VERIFICATION.SUCCESS,
  message,
});

export const sendEmailVerificationFailure = (error) => ({
  type: types.SEND_EMAIL_VERIFICATION.FAILURE,
  error,
});

export const createAdminSuccess = (message) => ({
  type: types.CREATE_ADMIN.SUCCESS,
  message,
});

export const createAdminFailure = (error) => ({
  type: types.CREATE_ADMIN.FAILURE,
  error,
});

export const logout = (history) => ({
  type: types.LOGOUT.REQUEST,
  history,
});

export const logoutSuccess = () => ({
  type: types.LOGOUT.SUCCESS,
});

export const logoutFailure = (error) => ({
  type: types.LOGOUT.FAILURE,
  error,
});

export const fetchAdmin = (credential) => ({
  type: types.FETCH_ADMIN.REQUEST,
  credential,
});

export const fetchAdminFailure = (error) => ({
  type: types.FETCH_ADMIN.FAILURE,
  error,
});

export const fetchAdminSuccess = (admin, message) => ({
  type: types.FETCH_ADMIN.SUCCESS,
  admin,
  message,
});

export const syncAdmin = (credential) => ({
  type: types.SYNC_ADMIN,
  credential,
});
