import React, { useEffect, useState, useMemo, useCallback } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Spinner,
  Alert,
} from 'reactstrap';
import * as netRevenuesActions from '../../store/revenues/sites/actions';
import * as sitesActions from '../../store/sites/actions';
import { useDispatch, useSelector } from 'react-redux';
import {
  isAdmin as isAdminSelector,
  isManager as isManagerSelector,
} from '../../selectors/auth';
import { actualPublisher } from '../../selectors/publisher';
import { actualNetwork } from '../../selectors/network';

import { selectPublishersByActualRole } from '../../selectors/publisher';
import { totalMetric, eCPM as eCPMSelector } from '../../selectors/revenue';

import Modal from '../../components/Common/Modal/Modal';
import ShowSingleElement from '../../components/Common/ShowSingleElement';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import DateRangeFilter from '../../components/Common/DateRangeFilter/DateRangeFilter';
import MiniCard from '../../components/Common/MiniCard';
import { AdsTxtAlert } from '../../components/Publishers/ShowAdsTxt';
import SiteRevenueGraph from '../../components/Revenues/SiteRevenueGraph';
import DataTableNext from '../../components/Common/DataTable/DataTableNext';
import createTableColumns from '../../components/Revenues/siteTableColumns';
import {
  ToggleColumnButton,
  ExportButtonPlacement,
} from '../../components/Common/DataTable/tableActionButtons';
import { addOrRemove } from '../../helpers/sharedFunction';

import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { REVENUE_LEVEL } from '../../config/revenue';

const SiteNetRevenues = (props) => {
  const loading = useSelector((state) => state.SiteNetRevenue.loading);

  const netRevenues = useSelector((state) => state.SiteNetRevenue.revenues);
  const publishers = useSelector(selectPublishersByActualRole);
  const sites = useSelector((state) => state.Site.sites);

  const isAdmin = useSelector(isAdminSelector);
  const isManager = useSelector(isManagerSelector);
  const isStaff = isAdmin || isManager;

  const publisher = useSelector(actualPublisher);
  const network = useSelector(actualNetwork);

  const totalNetRevenue = useSelector(
    totalMetric('totalNetRevenue', REVENUE_LEVEL.SITE),
  );
  const eCPM = useSelector(eCPMSelector);
  const impressions = useSelector(
    totalMetric('impressions', REVENUE_LEVEL.SITE),
  );

  const [selectedRevenue, setSelectedRevenue] = useState(null);
  const [viewModal, setViewModal] = useState(false);

  const [filters, setFilters] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);

  const startDate = useSelector((state) => state.SiteNetRevenue.startDate);
  const endDate = useSelector((state) => state.SiteNetRevenue.endDate);
  const dataFilters = useSelector((state) => state.SiteNetRevenue.filters);

  const momentStartDate = startDate ? moment(startDate) : null;
  const momentEndDate = endDate ? moment(endDate) : null;

  const maxDate = 'today';
  const minDate = '01/11/2024';

  const [selectedFilters, setSelectedFilters] = useState({
    startDate: momentStartDate || moment.utc(),
    endDate: momentEndDate || moment.utc(),
    publisherId: dataFilters?.publisherId || (isStaff ? '' : publishers[0]?.id),
    siteIds: dataFilters?.siteIds,
  });

  const dispatch = useDispatch();

  const refresh = useCallback(
    ({ startDate, endDate, publisherId, siteIds }) => {
      const selectedStartDate = startDate.utc().toDate();
      const selectedEndDate = endDate.utc().toDate();
      dispatch(
        netRevenuesActions.fetchRevenues(selectedStartDate, selectedEndDate, {
          publisherId,
          siteIds,
        }),
      );
      setSelectedFilters({ startDate, endDate, publisherId, siteIds });
    },
    [],
  );

  useEffect(() => {
    netRevenues.length === 0 && refresh(selectedFilters);
    dispatch(sitesActions.syncSites());
  }, []);

  const openViewModal = (insight) => {
    setSelectedRevenue(insight);
    setViewModal(true);
  };

  const { t } = useTranslation();

  const metrics = [
    {
      title: t('Net Revenue'),
      text: `${isFinite(totalNetRevenue) ? totalNetRevenue.toFixed(2) : 0} €`,
      color: 'dark',
      icon: 'bx bx-euro',
    },
    {
      title: t('Impressions'),
      text: Math.floor(impressions),
      color: 'warning',
      icon: 'fas fa-ad',
    },
    {
      title: t('eCPM'),
      text: `${isFinite(eCPM) ? eCPM.toFixed(2) : 0} €`,
      color: 'success',
      icon: 'bx bx-euro',
    },
  ];

  const columns = useMemo(
    () => createTableColumns(sites, openViewModal, isStaff, t),
    [sites, isStaff, t],
  );
  const [hiddenColumns, setHiddenColumns] = useState(
    columns.filter(({ hidden }) => !!hidden).map(({ name }) => name),
  );
  const handleToggleColumn = (name) => {
    setHiddenColumns(addOrRemove(hiddenColumns, name));
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title="Site Revenues"
            breadcrumbItem="List"
            onRefresh={() => refresh(selectedFilters)}
            loading={loading}
          />
          <DateRangeFilter
            onUpdate={refresh}
            minDate={minDate}
            maxDate={maxDate}
            startDate={selectedFilters.startDate}
            endDate={selectedFilters.endDate}
            loading={loading}
            showPublisherFilters
            initialFilters={selectedFilters}
            showAllFilter={isStaff}
          />
          {(publisher || network) && false && (
            <Row>
              <Col md="12">
                <AdsTxtAlert
                  element={publisher || network}
                  type={publisher ? 'publisher' : 'network'}
                />
              </Col>
            </Row>
          )}
          <Row>
            <Col md="3">
              {metrics.map((metric, index) => (
                <MiniCard
                  key={'metric_' + index}
                  {...metric}
                  loading={loading}
                />
              ))}
            </Col>
            <Col md="9">
              <SiteRevenueGraph
                dateRange={{
                  startDate: momentStartDate,
                  endDate: momentEndDate,
                }}
                loading={loading}
              />
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row className="align-items-center justify-content-end mb-2">
                    <Spinner
                      color="secondary"
                      animation="border"
                      hidden={!loading}
                    />
                    <ToggleColumnButton
                      columns={columns}
                      hiddenColumns={hiddenColumns}
                      onToggleColumn={handleToggleColumn}
                    />
                    <ExportButtonPlacement />
                  </Row>
                  <DataTableNext
                    loading={loading}
                    rows={netRevenues}
                    columns={columns}
                    filters={filters}
                    onFiltersChange={setFilters}
                    sorting={sorting}
                    onSortingChange={setSorting}
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    hiddenColumns={hiddenColumns}
                    onHiddenColumnsChange={setHiddenColumns}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                  />
                  <Row className="mt-2">
                    <Col md="12">
                      <Alert color="warning">
                        <div className="d-flex align-items-center">
                          <i class={`bx bx-error font-size-24 mr-3`}></i>
                          <div>
                            {t(
                              'There might be differences between figures in your SpeakUp CMS dashboard above and the billing report you receive from Audioboost. Figures in SpeakUp CMS dashboard are not final for billing purposes.',
                            )}
                          </div>
                        </div>
                      </Alert>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal
            size="lg"
            title="Site Revenue"
            isOpen={viewModal}
            toggle={() => setViewModal(!viewModal)}
            showClose
            scrollable={false}>
            <ShowSingleElement
              element={selectedRevenue}
              collection="dailySiteNetRevenues"
              icon="mdi mdi-web"
            />
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default SiteNetRevenues;
