import React from 'react';

import { Container } from 'reactstrap';

const Onboarding = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <h1>Onboarding</h1>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Onboarding;
