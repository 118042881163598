import types from './actionTypes';

export const resetState = () => ({
  type: types.RESET_PODCAST_STATE,
});

export const createPodcast = (
  url,
  isPremium = false,
  audioDescription = false,
) => ({
  type: types.CREATE_PODCAST.REQUEST,
  url,
  isPremium,
  audioDescription,
});

export const createPodcastSuccess = () => ({
  type: types.CREATE_PODCAST.SUCCESS,
});

export const createPodcastFailure = (error) => ({
  type: types.CREATE_PODCAST.FAILURE,
  error,
});

export const updatePodcast = (podcast) => ({
  type: types.UPDATE_PODCAST.REQUEST,
  podcast,
});

export const updatePodcastSuccess = (podcast) => ({
  type: types.UPDATE_PODCAST.SUCCESS,
  podcast,
});

export const updatePodcastFailure = (error) => ({
  type: types.UPDATE_PODCAST.FAILURE,
  error,
});

export const fetchPodcasts = (
  startDate,
  endDate,
  filters = null,
  startAfterId = null,
  limit = null,
) => ({
  type: types.FETCH_PODCASTS.REQUEST,
  startDate,
  endDate,
  filters,
  startAfterId,
  limit,
});

export const fetchPodcastsSuccess = (
  podcasts,
  startDate,
  endDate,
  filters,
  loadMore = false,
) => ({
  type: types.FETCH_PODCASTS.SUCCESS,
  podcasts,
  startDate,
  endDate,
  filters,
  loadMore,
});

export const fetchPodcastsFailure = (error) => ({
  type: types.FETCH_PODCASTS.FAILURE,
  error,
});

export const fetchPodcastsByIds = (ids) => ({
  type: types.FETCH_PODCASTS_BY_IDS.REQUEST,
  ids,
});

export const fetchPodcastsByIdsSuccess = (podcasts) => ({
  type: types.FETCH_PODCASTS_BY_IDS.SUCCESS,
  podcasts,
});

export const fetchPodcastsByIdsFailure = (error) => ({
  type: types.FETCH_PODCASTS_BY_IDS.FAILURE,
  error,
});

export const fetchRelatedPodcasts = (siteId, clusterId) => ({
  type: types.FETCH_RELATED_PODCASTS.REQUEST,
  siteId,
  clusterId,
});

export const fetchRelatedPodcastsSuccess = (podcasts) => ({
  type: types.FETCH_RELATED_PODCASTS.SUCCESS,
  podcasts,
});

export const fetchRelatedPodcastsFailure = (error) => ({
  type: types.FETCH_RELATED_PODCASTS.FAILURE,
  error,
});
