import '../../lib/availity/yup';
import * as yup from 'yup';

const ADS_TXT_STRING = /^[a-zA-Z0-9.,\s-{}]{4,}$/;

const formValidation = yup.object().shape({
  name: yup.string().required(),
  languageTag: yup.string().required(),
  adswizzZoneGroupId: yup.number().positive().required(),
  adsTxtRows: yup
    .string()
    .test('adsTxtRows', 'Invalid ads.txt format', (value) => {
      if (!value) return true; // Allow empty value
      const rows = value.split('\n');
      const isValidFormat = rows.every((row) =>
        ADS_TXT_STRING.test(row.trim()),
      );
      const containsSellerId = rows.some((row) => row.includes('{{sellerId}}'));
      const containsTruvidSellerId = rows.some((row) =>
        row.includes('{{truvidSellerId}}'),
      );
      return isValidFormat && containsSellerId && containsTruvidSellerId;
    }),
});

export default formValidation;
